import { useMatches } from "@remix-run/react";
import { Params } from "@remix-run/router";
import { DefaultAdminRoles } from "~/application/dtos/shared/DefaultAdminRoles";
import { DefaultAppRoles } from "~/application/dtos/shared/DefaultAppRoles";
import { LinkedAccountStatus } from "~/application/enums/tenants/LinkedAccountStatus";
import { TenantUserType } from "~/application/enums/tenants/TenantUserType";
import { getTranslations } from "~/locale/i18next.server";
import { TimeFunction } from "~/modules/metrics/services/.server/MetricTracker";
import OnboardingService from "~/modules/onboarding/services/OnboardingService";
import { TenantEntitiesApi } from "~/utils/api/.server/TenantEntitiesApi";
import UrlUtils from "../app/UrlUtils";
import { EntityGroupWithDetails, getAllEntityGroups } from "../db/entities/entityGroups.db.server";
import { getLinkedAccountsCount } from "../db/linkedAccounts.db.server";
import { getMyGroups } from "../db/permissions/groups.db.server";
import { getAllRolesWithoutPermissions } from "../db/permissions/roles.db.server";
import { getUserRoleInAdmin, getUserRoleInTenant } from "../db/permissions/userRoles.db.server";
import { TenantSubscriptionWithDetails } from "../db/tenantSubscriptions.db.server";
import { TenantSimple, getMyTenants, getTenantSimple, getTenantUserType } from "../db/tenants.db.server";
import { TenantRelationshipWithDetails, getTenantRelationshipsFromByUserTenants } from "../db/tenants/tenantRelationships.db.server";
import { getAllTenantTypes } from "../db/tenants/tenantTypes.db.server";
import { getUser } from "../db/users.db.server";
import { promiseHash } from "../promises/promiseHash";
import { getTenantIdFromUrl } from "../services/.server/urlService";
import { getActiveTenantSubscriptions } from "../services/subscriptionService";
import { getUserInfo } from "../session.server";
import { AppOrAdminData } from "./useAppOrAdminData";

export type AppLoaderData = AppOrAdminData & {
  currentTenant: TenantSimple;
  mySubscription: TenantSubscriptionWithDetails | null;
  currentRole: TenantUserType;
  pendingInvitations: number;
  childTenants: TenantRelationshipWithDetails[];
};

export function useAppData(): AppLoaderData {
  const paths: string[] = ["routes/app.$tenant", "routes/app"];
  return (useMatches().find((f) => paths.includes(f.id.toLowerCase()))?.data ?? {}) as AppLoaderData;
}


